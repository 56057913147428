<template>
  <div>
    <header>
      <div>
        <h1>Help</h1>
      </div>
    </header>
    <section class="menubar">
      <button class="btn btn-primary" @click="() => scrollMeTo('userguide')">
        User guide
      </button>
      <button class="btn btn-primary" @click="() => scrollMeTo('qna')">
        Q &amp; A
      </button>
      <button
        class="btn btn-primary"
        @click="() => scrollMeTo('browser_compatibility')"
      >
        Browser Compatibility
      </button>
    </section>
    <section class="container-md my-40 help">
      <div ref="userguide">
        <h2 class="header-underline">User guide</h2>
        <div class="my-30">
          <h3>I) Getting started</h3>
          <div class="article">
            <div>
              To start prediction, the user can access by clicking
              <span class="colored">“Predictor”</span> menu at navigation bar,
              or clicking <span class="colored">“Predict Now”</span> button at
              this landing page.
            </div>
            <div>
              <v-img fit src="../assets/help/1.webp" class="img" />
            </div>
            <div>
              The submission page will be appeared to the user for pIC<sub
                >50</sub
              >
              prediction.
            </div>
            <div>
              <v-img fit src="../assets/help/2.webp" class="img" />
            </div>
          </div>
        </div>
        <div class="my-30">
          <h3>II) Submission</h3>
          <div class="article">
            <div>
              To avoid misleading chemical structure, the submission system
              supports only
              <a
                href="https://en.wikipedia.org/wiki/Chemical_table_file#SDF"
                target="_blank"
                class="colored"
                >“SDF file format”</a
              >. User can add the input compound(s) at the text box, or upload
              SDF file at the <span class="colored">“File input”</span>.
              Thereafter, user has confirm for pIC<sub>50</sub> calculation by
              clicking <span class="colored">“Submit”</span> button at below of
              page.
            </div>
            <div>
              The system supports a query compound for prediction by containing
              <span class="colored">maximum 60 atoms</span>. The interval time
              for prediction is limited to
              <span class="colored">150 compounds for a submission</span>. User
              can click SDF example files at the top of the text box for
              investigating the format pattern.
            </div>
            <div>
              <v-img fit src="../assets/help/3.webp" class="img" />
            </div>
            <div>
              Then, the submitted molecules will be executed at the moment. The
              prediction time is depended on a number of query compounds.
            </div>
            <div>
              <v-img fit src="../assets/help/4.webp" class="img" />
            </div>
          </div>
        </div>
        <div class="my-30">
          <h3>III) Results</h3>
          <div class="article">
            <div>
              If the submission is success, user will get the results page. The
              table result shows the predicted pIC<sub>50</sub> by each
              molecule, ordering by the rank of pIC<sub>50</sub>. Note that the
              ID of the result is equivalent to the order of ID from the SDF
              input file.
            </div>
            <div>
              Moreover, the results will show the reliability of the prediction,
              calculating from the applicability domain analysis (ADAN) with
              k-nearest neighbors (kNN)-based method, and the active and
              inactive compounds classified by using the cut-off value
              (pIC<sub>50</sub>&nbsp;>= 6,
              resulting&nbsp;in&nbsp;active&nbsp;compound,&nbsp;meanwhile,&nbsp;pIC<sub>50</sub>&nbsp;&lt;
              6, resulting in&nbsp;inactive compound). User can retrieve the
              results in CSV format&nbsp; file by clicking
              <span class="colored">“DOWNLOAD RESULTS”</span> button.
            </div>
            <div>
              <v-img fit src="../assets/help/5.webp" class="img" />
            </div>
          </div>
        </div>
        <div class="my-30">
          <h3>IV) Interpretation</h3>
          <div class="article">
            <div>
              The interpretation is shown at below the table result. The bar
              chart and spider chart will represent for top 10 predicted pIC<sub
                >50</sub
              >
              values. In addition, the physicochemical properties of top 10
              predicted pIC<sub>50</sub> values will be appeared by carousel
              section.
            </div>
            <div>
              User can download these charts by clicking
              <span class="colored">“DOWNLOAD VISUALIZATION”</span> button, and
              molecular properties for top 10 predicted pIC<sub>50</sub> values
              in CSV format file by clicking
              <span class="colored">“Download CSV file”</span>.
            </div>
            <div>
              <v-img fit src="../assets/help/6.webp" class="img" />
            </div>
            <div>
              User can interpret the data point distribution by comparing the
              results of each query molecule with scatter plot. This plot is
              shown by following up with
              <a
                href="https://en.wikipedia.org/wiki/Lipinski%27s_rule_of_five"
                target="_blank"
                rel="noopener noreferrer"
                class="colored"
                >RO5 criterion</a
              >
              including Log P, hydrogen bond acceptor (HBA), hydrogen bond donor
              (HBD), and molecular weight.
            </div>
            <div>
              The plot allows user to control the visualization and download the
              image by hovering on top-right and clicking the sub-menu on the
              plot.
            </div>
            <div>
              <v-img fit src="../assets/help/7.webp" class="img" />
            </div>
          </div>
        </div>
      </div>

      <div ref="qna">
        <h2 class="header-underline">Q &amp; A</h2>
        <div class="my-20">
          <h3>
            I) What does it mean when the popup box “Something went wrong,
            please submit again later” is appeared?
          </h3>
          <div class="article">
            <div>
              <v-img contain src="../assets/help/8.webp" class="img" />
            </div>
            <div>
              <b>Answer:</b> Usually, this error is shown when the SDF input
              file is broken. This broken is because the file is contained with
              <br />
              I) The incomplete of molecule structure <i>e.g.</i> explicit
              valence electron. <br />
              II) The molecule has more than 60 atoms <br />
              III) The molecule has more than 150 molecules.
            </div>
            <div>
              User can fix the chemical structure by using
              <a
                href="https://docs.chemaxon.com/display/docs/structure-checker-command-line-application.md"
                target="_blank"
                rel="noopener noreferrer"
                class="colored"
                >“structurecheck”</a
              >
              command-line from ChemAxon package by downloading from
              <a
                href="https://chemaxon.com/products/chemical-structure-representation-toolkit"
                target="_blank"
                rel="noopener noreferrer"
                class="colored"
                >this website</a
              >.
            </div>
          </div>
        </div>
      </div>

      <div ref="browser_compatibility">
        <h2 class="header-underline">Browser compatibility</h2>
        <div class="my-20">
          <div class="article">
            <div>
              The EGFRNet website was constructed on responsive design, be
              compatible for computer, tablet, and mobile devices. Here is a
              preferred browser for this website.
            </div>
            <div style="overflow-x: auto;">
              <table>
                <thead>
                  <tr>
                    <td>Browser</td>
                    <td>Windows</td>
                    <td>Linux</td>
                    <td>macOS</td>
                    <td>iOS</td>
                    <td>Android</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Firefox</td>
                    <td>✔︎</td>
                    <td>✔︎</td>
                    <td>✔︎</td>
                    <td>✔︎</td>
                    <td>✔︎</td>
                  </tr>
                  <tr>
                    <td>Google Chrome</td>
                    <td>✔︎</td>
                    <td>✔︎</td>
                    <td>✔︎</td>
                    <td>✔︎</td>
                    <td>✔︎</td>
                  </tr>
                  <tr>
                    <td>Microsoft Edge</td>
                    <td>✔︎</td>
                    <td>✔︎</td>
                    <td>✔︎</td>
                    <td>✔︎</td>
                    <td>✔︎</td>
                  </tr>
                  <tr>
                    <td>Safari</td>
                    <td>N.A.</td>
                    <td>N.A.</td>
                    <td>✔︎</td>
                    <td>✔︎</td>
                    <td>N.A.</td>
                  </tr>
                  <tr>
                    <td>Opera</td>
                    <td>✔︎</td>
                    <td>✔︎</td>
                    <td>✔︎</td>
                    <td>✔︎</td>
                    <td>✔︎</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "help",
  components: {
    //
  },
  methods: {
    scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop - 100;

      window.scrollTo(0, top);
    }
  },
  props: {
    setActive: Function
  },
  created() {
    this.setActive(this.$route.name.toLowerCase());
  }
};
</script>

<style scoped>
header::before {
  background-image: url("../assets/images/science-background-with-molecule-atom-abstract-structure-science-medical-background-3d-illustration.webp");
}
h2 {
  color: #582e91;
}
h3,
.colored {
  color: #8571af;
}
.article > div {
  margin: 20px;
}
.help > div:nth-child(2) .img:last-child {
  max-width: 350px;
}
.help > div {
  margin: 40px 0px;
}
.img {
  max-width: 80%;
  min-width: 100px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  -moz-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
.menubar {
  background-color: #f2f2f2;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.menubar .btn {
  margin: 20px;
}
table thead {
  background-color: #582e91;
  color: #ffffff;
}

table thead td {
  font-family: "IBMPlexSans Bold";
}

table td {
  padding: 10px 40px;
  text-align: center;
}

table td:first-child {
  text-align: left;
}

table tbody tr:nth-child(even) {
  background-color: #e5e6f5;
}

@media screen and (max-width: 991px) {
  .help .img {
    max-width: 100%;
  }
  .help > div:nth-child(2) .img:last-child {
    max-width: initial;
  }
}

@media screen and (max-width: 575px) {
  .menubar {
    flex-direction: column;
  }
}
</style>
